import React, {ReactNode, useRef} from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Animated,
  StyleProp,
  ViewStyle,
} from 'react-native';

import {Check} from '@suranceadmin/icons/native';

import {Accessibility, Theme} from '../../types';
import {withTheme} from '../../core/theming';

type Props = {
  isOn: boolean;
  onToggle: (val: boolean) => void;
  thumbOnStyle?: StyleProp<ViewStyle>;
  thumbOffStyle?: StyleProp<ViewStyle>;
  trackOnStyle?: StyleProp<ViewStyle>;
  trackOffStyle?: StyleProp<ViewStyle>;
  icon?: ReactNode;
  disabled?: boolean;
  theme: Theme;
  accessibility?: Accessibility;
};

const dimensions = {
  width: 40,
  circleWidth: 24,
  circleHeight: 24,
  translateX: 24,
};

const switchAccessibilityState = (isOn: boolean) => {
  const accessbilityState = {
    accessibilityState: {
      checked: isOn,
    },
  };
  return accessbilityState;
};

const Switch = (props: Props): JSX.Element => {
  const {
    isOn = false,
    onToggle = () => undefined,
    thumbOnStyle,
    thumbOffStyle,
    trackOnStyle,
    trackOffStyle,
    icon,
    disabled = false,
    theme: {colors, isRTL},
    accessibility,
  } = props;

  let toValue;
  const offsetX = useRef(new Animated.Value(0)).current;

  const switchIcon = icon || (
    <Check width={12} color={colors.background.white} />
  );

  const createTrackStyle = () => [
    styles.track,
    {
      backgroundColor: colors.button.disabled,
    },
    isOn ? trackOnStyle : trackOffStyle,
  ];

  const createThumbStyle = () => [
    styles.thumb,
    {
      backgroundColor: isOn ? colors.text.link : colors.text.disabled,
      transform: [{translateX: offsetX}],
    },
    isOn ? thumbOnStyle : thumbOffStyle,
  ];

  if (!isRTL && isOn) {
    toValue = dimensions.width - dimensions.translateX;
  } else if (isRTL && isOn) {
    toValue = -dimensions.width + dimensions.translateX;
  } else {
    toValue = 0;
  }

  Animated.timing(offsetX, {
    toValue,
    useNativeDriver: true,
    duration: 300,
  }).start();

  return (
    <View style={styles.container} {...props}>
      <TouchableOpacity
        {...accessibility}
        {...switchAccessibilityState(isOn)}
        accessibilityRole={'switch'}
        style={createTrackStyle()}
        activeOpacity={0.8}
        onPress={() => (disabled ? null : onToggle(!isOn))}>
        <Animated.View style={createThumbStyle()}>
          {isOn && switchIcon}
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  track: {
    justifyContent: 'center',
    width: 40,
    borderRadius: 20,
    paddingVertical: 12,
    paddingHorizontal: 10,
  },
  thumb: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    left: 0,
    position: 'absolute',
    width: dimensions.circleWidth,
    height: dimensions.circleHeight,
    borderRadius: dimensions.circleWidth / 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.5,
    elevation: 1.5,
  },
});
export default withTheme(Switch);

import React from 'react';
import {View, StyleSheet, Text, Pressable} from 'react-native';
import FlashMessage, {
  showMessage as FlashShowMessage,
  hideMessage as FlashHideMessage,
  FlashMessageProps,
  MessageComponentProps,
  MessageOptions,
} from 'react-native-flash-message';
import CircularProgress from '../Progress/CircularProgress';
import Swipeable from './Swipeable';
import {ValidationApprove} from '@suranceadmin/icons/native';
import {getStatusBarHeight} from '../../constants';
import {useTheme} from '../../core/theming';
import {Accessibility} from '../../types';

type SuranceMessageComponent = MessageComponentProps & Accessibility;

const LocalNotificationBox = (props: FlashMessageProps): JSX.Element => {
  return <FlashMessage MessageComponent={MessageComponent} {...props} />;
};

const MessageComponent = (props: SuranceMessageComponent) => {
  const {
    message: {duration, message},
    accessible,
    accessibilityLabel,
    testID,
  } = props;
  const theme = useTheme();

  const hideMessage = () => {
    FlashHideMessage();
  };

  return (
    <Swipeable onSwipeAway={hideMessage}>
      <Pressable
        accessible={accessible}
        accessibilityLabel={accessibilityLabel}
        testID={testID}
        style={[
          styles.container,
          {
            backgroundColor: theme.colors.icon.valid,
            marginTop: getStatusBarHeight(),
          },
        ]}
        onPress={hideMessage}>
        <View style={styles.textView}>
          <Text style={[styles.title, theme.fonts.caption1Bold]}>
            {message}
          </Text>
        </View>

        <View style={styles.iconContainer}>
          <CircularProgress
            progress={0}
            startProgress={100}
            radius={16}
            strokeWidth={1}
            duration={duration}
            tintColor="white"
            color="transparent"
          />

          <ValidationApprove
            style={styles.icon}
            color="white"
            width={26}
            height={26}
          />
        </View>
      </Pressable>
    </Swipeable>
  );
};

export const showMessage = ({
  duration,
  message,
  ...props
}: MessageOptions): void => {
  const calcDuration = duration || calculateDuration(message);
  FlashShowMessage({duration: calcDuration, message, ...props});
};

export const hideMessage = (): void => {
  FlashHideMessage();
};

const calculateDuration = (text: string): number => {
  const duration = text.length / 30;

  if (duration < 3) {
    return 3000;
  } else if (duration > 10) {
    return 10000;
  } else {
    return duration * 1000;
  }
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    minHeight: 68,
    marginHorizontal: 8,
    borderRadius: 2,
  },
  textView: {
    flex: 1,
    marginVertical: 24,
    marginStart: 32,
    marginEnd: 8,
  },
  title: {
    color: 'white',
  },
  iconContainer: {
    width: 32,
    height: 32,
    marginTop: 18,
    marginEnd: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    position: 'absolute',
  },
});

export default LocalNotificationBox;

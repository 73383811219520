import React, {useRef, useCallback, useEffect} from 'react';
import {withTheme} from '../../core/theming';
import {Theme, Accessibility} from '../../types';
import Text, {TextType} from '../Typography/Text';
import {
  View,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import Badge, {Props as BadgeProps} from '../Badge';

type Value = {
  name: string;
  badge?: Omit<BadgeProps, 'theme'>;
  accessibility?: Accessibility;
};

interface Props {
  onChange?: (index: number) => void;
  style?: StyleProp<ViewStyle>;
  values?: Value[];
  selectedIndex?: number;
  theme: Theme;
}

const Tabs = ({
  style,
  values = [],
  selectedIndex = 0,
  theme: {colors, isRTL},
  onChange,
}: Props) => {
  const flatListRef = useRef<FlatList>(null);

  useEffect(() => {
    if (values?.length > selectedIndex) {
      flatListRef?.current?.scrollToIndex({
        animated: true,
        index: selectedIndex,
        viewPosition: 0.5,
      });
    }
  }, [selectedIndex, values]);

  const renderItemBadge = useCallback((badge: Omit<BadgeProps, 'theme'>) => {
    return <Badge {...badge} />;
  }, []);

  const renderItem = useCallback(
    ({item, index}: {item: Value; index: number}) => {
      return (
        <View style={{justifyContent: 'center'}}>
          <TouchableOpacity
            accessibilityRole={onChange ? 'button' : undefined}
            {...item?.accessibility}
            style={[
              styles.itemButton,
              {
                backgroundColor:
                  index === selectedIndex ? colors.line.light : 'transparent',
              },
            ]}
            onPress={() => onChange && onChange(index)}>
            <Text type={TextType.Body}>{item.name}</Text>
            {item.badge && renderItemBadge(item.badge)}
          </TouchableOpacity>
        </View>
      );
    },
    [onChange, selectedIndex, colors, renderItemBadge],
  );

  return (
    <View
      style={[
        {minHeight: 56, backgroundColor: colors.background.white},
        style,
      ]}>
      <FlatList
        contentContainerStyle={styles.flatlist}
        horizontal
        showsHorizontalScrollIndicator={false}
        ref={flatListRef}
        data={values || []}
        keyExtractor={(item) => item.name}
        renderItem={renderItem}
        onScrollToIndexFailed={() => console.log('scrollToIndex Error')}
        // This is needed to fix bug with wrong index on iOS when RTL
        // direction is only for iOS, so no need to check Android
        style={isRTL && {direction: 'ltr'}}
        inverted={isRTL && Platform.OS === 'ios'}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flatlist: {
    justifyContent: 'center',
    minWidth: '100%',
    padding: 8,
  },
  itemButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    height: 40,
    borderRadius: 20,
  },
});

export default withTheme(Tabs);

import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import Svg, {G, Circle} from 'react-native-svg';
import Animated, {
  useAnimatedProps,
  withTiming,
  withDelay,
} from 'react-native-reanimated';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

type Props = {
  progress?: number;
  startProgress?: number;
  radius?: number;
  strokeWidth?: number;
  duration?: number;
  delay?: number;
  tintColor?: string;
  color?: string;
};

const CirculatProgress = (props: Props): JSX.Element => {
  const {
    progress = 0,
    startProgress = 0,
    radius = 40,
    strokeWidth = 10,
    duration = 500,
    tintColor = 'black',
    color = 'gray',
    delay = 0,
  } = props;
  const circumference = 2 * Math.PI * radius;
  const halfCircle = radius + strokeWidth;
  const [animatedProgress, setAnimatedProgress] = useState(startProgress);

  useEffect(() => {
    setAnimatedProgress(progress);
  }, [progress]);

  const animatedProps = useAnimatedProps(() => {
    const newStrokeDashoffset =
      circumference - (circumference * animatedProgress) / 100;

    return {
      strokeDashoffset: withDelay(
        delay,
        withTiming(newStrokeDashoffset, {duration}),
      ),
    };
  });

  return (
    <View style={{width: radius * 2, height: radius * 2}}>
      <Svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>
        <G rotation="-90" origin={`${halfCircle}, ${halfCircle}`}>
          <AnimatedCircle
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={tintColor}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            animatedProps={animatedProps}
          />
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeOpacity=".1"
          />
        </G>
      </Svg>
    </View>
  );
};

export default CirculatProgress;

import React, {FunctionComponent, useState} from 'react';
import {
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {withTheme} from '../../core/theming';
import {Theme} from '../../types';
import Text, {TextType} from '../Typography/Text';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Transparent = 'transparent',
  Warning = 'warning',
}

type Props = {
  onPress?: () => void;
  disabled?: boolean;
  type?: ButtonType;
  style?: StyleProp<ViewStyle>;
  textType?: TextType;
  textStyle?: StyleProp<ViewStyle>;
  icon?: React.ReactElement;
  theme: Theme;
};

type ButtonColors = {
  background: string;
  text: string;
  border: string;
};

type ButtonProperties = {
  colors: ButtonColors;
  textType: TextType;
};

type GetButtonPropsType = {
  type: ButtonType;
  disabled?: boolean;
  theme: Theme;
};

function getButtonProps({
  type,
  disabled,
  theme: {
    colors: {button: themeButton, text: themeText},
  },
}: GetButtonPropsType): ButtonProperties {
  let props;
  switch (type) {
    case ButtonType.Primary: {
      props = {
        textType: TextType.ButtonPrimary,
        colors: {
          background: themeButton.primary,
          text: themeText.ondark,
          border: themeButton.primary,
        },
      };
      break;
    }
    case ButtonType.Secondary: {
      props = {
        textType: TextType.ButtonPrimary,
        colors: {
          background: themeButton.secondary,
          text: themeText.buttonSecondary,
          border: themeButton.secondaryBorder,
        },
      };
      break;
    }
    case ButtonType.Transparent: {
      props = {
        textType: TextType.ButtonPrimary,
        colors: {
          background: 'transaprent',
          text: themeText.ondark,
          border: themeButton.transparentBorder,
        },
      };
      break;
    }
    case ButtonType.Warning: {
      props = {
        textType: TextType.ButtonPrimary,
        colors: {
          background: themeButton.negative,
          text: themeText.ondark,
          border: themeButton.negative,
        },
      };
      break;
    }
    default: {
      props = {
        textType: TextType.ButtonPrimary,
        colors: {
          background: themeButton.primary,
          text: themeText.ondark,
          border: themeButton.primary,
        },
      };
    }
  }

  if (disabled) {
    props.colors = {
      background: themeButton.disabled,
      text: themeText.disabled,
      border: themeButton.disabled,
    };
  }
  return props;
}

const buttonAccessibilityState = (isDisabled: boolean) => {
  const accessbilityState = {
    accessibilityState: {
      disabled: isDisabled,
    },
  };
  return accessbilityState;
};

const Button: FunctionComponent<Props> = ({
  disabled = false,
  type = ButtonType.Primary,
  style,
  textType,
  textStyle,
  children,
  icon,
  theme,
  ...props
}) => {
  const [radius, setRadius] = useState(styles.container.borderRadius);
  const {colors, textType: defaultTextType} = getButtonProps({
    type,
    disabled,
    theme,
  });
  const containerStyle = {
    backgroundColor: colors.background,
    borderColor: colors.border,
    borderRadius: radius,
  };

  // Called if button becomes multiline to re-calculate readiues
  const onLayout = ({nativeEvent}: LayoutChangeEvent) => {
    setRadius(nativeEvent?.layout?.height || 0 / 2);
  };
  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.container,
        icon ? {paddingVertical: 0} : null,
        containerStyle,
        style,
      ]}
      activeOpacity={0.5}
      accessibilityRole={'button'}
      {...buttonAccessibilityState(disabled)}
      disabled={disabled}
      onLayout={onLayout}>
      {icon ? (
        React.cloneElement(icon, {color: colors.text})
      ) : (
        <Text
          style={[styles.text, textStyle]}
          type={textType || defaultTextType}
          color={colors.text}
          numberOfLines={2}>
          {children}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 40,
    minWidth: 80,
    borderWidth: 1.5,
    borderRadius: 16,
    paddingVertical: 9,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 13,
    width: '100%',
  },
});

export default withTheme(Button);

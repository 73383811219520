import {Colors} from '../types';

const DefaultColors: Colors = {
  background: {
    white: '#FFFFFF',
    default: '#EFF2F5',
    header: '#000033',
    headerModal: '#133251',
    important: '#FFF2D6',
    warning: '#BA2D25',
  },
  button: {
    primary: '#1A61A7',
    secondary: '#FFFFFF',
    negative: '#BA2D25',
    disabled: '#DDE2E8',
    secondaryBorder: '#1A61A7',
    transparentBorder: '#FFFFFF',
  },
  text: {
    default: '#133251',
    light: '#546B84',
    ondark: '#FFFFFF',
    disabled: '#93A3B6',
    link: '#1A61A7',
    warning: '#BA2D25',
    buttonSecondary: '#1A61A7',
  },
  line: {
    default: '#93A3B6',
    light: '#DDE2E8',
  },
  level: {
    unknown: '#93A3B6',
    notSafe: '#BA2D25',
    mildlySafe: '#DB7B2B',
    fullySafe: '#60AF4A',
  },
  notification: {
    critical: '#BA2D25',
    important: '#DB7B2B',
    improvement: '#E5AF0B',
  },
  icon: {
    default: '#133251',
    link: '#1A61A7',
    ondark: '#FFFFFF',
    warning: '#BA2D25',
    critical: '#BA2D25',
    important: '#DB7B2B',
    improvement: '#E5AF0B',
    valid: '#60AF4A',
  },
};

export default DefaultColors;

import {Theme} from '../types';
import DefaultFonts from './fonts';
import DefaultColors from './colors';

const DefaultTheme: Theme = {
  colors: DefaultColors,
  fonts: DefaultFonts,
};

export default DefaultTheme;

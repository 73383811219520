import {Platform} from 'react-native';
import {Fonts} from '../types';

const DefaultFonts: Fonts = {
  headline1: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 42,
  },
  headline2: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 32,
  },
  subtitle: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 24,
  },
  subtitleBold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 24,
  },
  body: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 18,
  },
  bodyBold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 18,
  },
  bodyWide: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 18,
  },
  buttonPrimary: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: 16,
    textTransform: 'uppercase',
    ...Platform.select({
      ios: {
        marginBottom: -2,
      },
      android: {
        marginBottom: -2,
      },
    }),
  },
  buttonSecondary: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 18,
    textTransform: 'capitalize',
  },
  overline: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 16,
  },
  overlineBold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 16,
  },
  caption1: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 16,
  },
  caption1Bold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 16,
  },
  caption2: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 14,
  },
  caption2Bold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 14,
  },
  caption3: {
    fontFamily: 'System',
    fontWeight: 'normal',
    fontSize: 12,
  },
  caption3Bold: {
    fontFamily: 'System',
    fontWeight: 'bold',
    fontSize: 12,
  },
};

export default DefaultFonts;

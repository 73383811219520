import React from 'react';
import {PanGestureHandler} from 'react-native-gesture-handler';
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
  interpolate,
  runOnJS,
} from 'react-native-reanimated';

type Props = {
  onSwipeAway?: () => void;
  children: React.ReactNode;
};

type GestureContext = {
  startY: number;
};

const SWIPE_AWAY = -30;

const Swipeable = (props: Props): JSX.Element => {
  const {children, onSwipeAway} = props;
  const transY = useSharedValue(0);

  const onGestureEvent = useAnimatedGestureHandler({
    onStart: (_, ctx: GestureContext) => {
      ctx.startY = transY.value;
    },
    onActive: (event, ctx: GestureContext) => {
      transY.value = interpolate(
        ctx.startY + event.translationY,
        [0, 4],
        [0, 1],
      );
    },
    onEnd: (event) => {
      if (event.velocityY < SWIPE_AWAY && onSwipeAway) {
        runOnJS(onSwipeAway)();
      } else {
        transY.value = withSpring(0);
      }
    },
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: transY.value,
        },
      ],
    };
  });

  return (
    <PanGestureHandler onGestureEvent={onGestureEvent}>
      <Animated.View style={animatedStyle}>{children}</Animated.View>
    </PanGestureHandler>
  );
};

export default Swipeable;

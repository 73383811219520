import {Theme} from '../types';
import DefaultTheme from './DefaultTheme';

const DarkTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    button: {
      ...DefaultTheme.colors.button,
      secondary: '#000000',
      transparentBorder: '#000000',
    },
  },
};

export default DarkTheme;

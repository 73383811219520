import React, {useState} from 'react';
import {Theme, Accessibility} from '../../types';
import {withTheme} from '../../core/theming';
import {CheckboxOn, CheckboxOff} from '@suranceadmin/icons/native';
import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  StyleSheet,
  NativeSyntheticEvent,
  TextLayoutEventData,
} from 'react-native';

type Props = {
  onValueChange?: (value: boolean) => void;
  style?: ViewStyle;
  isChecked?: boolean;
  children?: React.ReactNode;
  theme: Theme;
  checkboxAccessibility?: Accessibility;
};

const checkboxAccessibilityState = (isChecked: boolean) => {
  const accessbilityState = {
    accessibilityState:  {
      checked: isChecked,
    },
  };
  return accessbilityState;
}

const Checkbox = ({
  onValueChange,
  style,
  isChecked = false,
  children,
  theme: {colors},
  checkboxAccessibility,
}: Props) => {
  const [alignItems, setAlignItems] = useState<'center' | 'flex-start'>(
    'flex-start',
  );

  const onPress = () => {
    if (onValueChange) {
      onValueChange(!isChecked);
    }
  };

  const renderCheckboxImage = () => {
    if (isChecked) {
      return <CheckboxOn color={colors.icon.link} />;
    } else {
      return <CheckboxOff color={colors.icon.link} />;
    }
  };

  const onTextLayout = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextLayoutEventData>) => {
    setAlignItems(nativeEvent?.lines?.length > 1 ? 'flex-start' : 'center');
  };
  return (
    <View style={[style, styles.container, {alignItems: alignItems}]}>
      <TouchableOpacity
        accessibilityRole={'checkbox'}
        {...checkboxAccessibility}
        {...checkboxAccessibilityState(isChecked)}
        style={styles.checkbox}
        onPress={onPress}>
        {renderCheckboxImage()}
      </TouchableOpacity>

      {children && (
        <Text style={styles.textView} onTextLayout={onTextLayout}>
          {children}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  checkbox: {
    paddingHorizontal: 8,
  },
  textView: {
    flex: 1,
    marginLeft: 8,
  },
});

export default withTheme(Checkbox);

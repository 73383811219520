import React from 'react';
import {getStatusBarHeight, getPaddingFromStatusBar} from '../../constants';
import {withTheme} from '../../core/theming';
import {Theme, Accessibility} from '../../types';
import Text, {TextType} from '../Typography/Text';
import {
  View,
  StatusBar,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { increasedTapZone } from '../../common';

type Props = {
  leftComponent?: {
    icon?: React.ReactNode;
    accessibility?: Accessibility;
    action?: () => void;
  };
  rightComponent?: {
    icon?: React.ReactNode;
    accessibility?: Accessibility;
    action?: () => void;
  };
  translucent?: boolean;
  barStyle?: 'default' | 'light-content' | 'dark-content';
  theme: Theme;
  title?: string;
  titleAccessibility?: Accessibility;
};

const Header = ({
  leftComponent,
  rightComponent,
  translucent = false,
  barStyle = 'light-content',
  theme: {colors},
  title,
  titleAccessibility,
}: Props) => {
  return (
    <View
      style={[
        styles.container,
        {backgroundColor: colors.background.headerModal},
      ]}>
      <StatusBar barStyle={barStyle} translucent={translucent} />

      <View style={{width: 70}}>
        {leftComponent?.icon && (
          <TouchableOpacity
            accessibilityRole={'button'}
            {...increasedTapZone()}
            {...leftComponent.accessibility}
            style={styles.leftViewButton}
            onPress={leftComponent?.action}>
            {leftComponent.icon}
          </TouchableOpacity>
        )}
      </View>

      <View style={styles.titleView}>
        <Text
          {...titleAccessibility}
          style={{textAlign: 'center'}}
          type={TextType.Subtitle}
          color={colors.text.ondark}
          numberOfLines={1}>
          {title}
        </Text>
      </View>

      <View style={{width: 70}}>
        {rightComponent?.icon && (
          <TouchableOpacity
            accessibilityRole={'button'}
            {...rightComponent.accessibility}
            style={styles.rightViewButton}
            onPress={rightComponent?.action}>
            {rightComponent.icon}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingTop: getStatusBarHeight() + getPaddingFromStatusBar(),
    paddingBottom: 20,
    alignItems: 'center',
    width: '100%',
  },
  leftViewButton: {
    justifyContent: 'center',
    paddingLeft: 24,
  },
  rightViewButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: 24,
  },
  titleView: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

export default withTheme(Header);

import React from 'react';
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import {withTheme} from '../../core/theming';
import {Theme, Accessibility} from '../../types';

const Card = (props: CardProps) => {
  const {
    children,
    theme,
    onPress,
    isShadowDisplayed = true,
    style,
    accessibility,
    activeOpacity,
  } = props;
  const customStyles = [
    styles.container,
    {
      backgroundColor: theme.colors.background.white,
      ...(isShadowDisplayed
        ? {
            boxShadow: '0px 2px 4px rgba(84, 107, 132, 0.1)',
            ...styles.nativeShadow,
          }
        : {}),
    },
    style,
  ];
  return typeof onPress === 'function' ? (
    <TouchableOpacity
      accessibilityRole={'button'}
      {...accessibility}
      onPress={onPress}
      style={customStyles}
      activeOpacity={activeOpacity}>
      {children}
    </TouchableOpacity>
  ) : (
    <View {...accessibility} style={customStyles}>
      {children}
    </View>
  );
};

type CardProps = {
  style?: StyleProp<ViewStyle>;
  theme: Theme;
  children: React.ReactNode;
  onPress?: () => void;
  isShadowDisplayed?: boolean;
  activeOpacity?: number;
  accessibility?: Accessibility;
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 32,
    paddingVertical: 24,
    borderRadius: 2,
  },
  nativeShadow: {
    shadowColor: 'rgba(84, 107, 132, 1)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.22,
    elevation: 3,
  },
});

export default withTheme(Card);

import React from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {withTheme} from '../../core/theming';
import {Accessibility, Theme} from '../../types';
import Text, {TextType} from '../Typography/Text';

export enum BadgeType {
  Default = 'default',
  Critical = 'critical',
  Important = 'important',
  Improvements = 'improvements',
}

export type Props = {
  style?: StyleProp<ViewStyle>;
  value?: string | number;
  type?: BadgeType;
  isBold?: boolean;
  theme: Theme;
  accessibility?: Accessibility;
};

const Badge = ({
  style,
  value,
  type = BadgeType.Default,
  isBold = false,
  theme: {colors},
  accessibility,
  ...props
}: Props) => {
  const getColor = (): string => {
    switch (type) {
      case BadgeType.Critical:
        return colors.notification.critical;
      case BadgeType.Important:
        return colors.notification.important;
      case BadgeType.Improvements:
        return colors.notification.improvement;
      default:
        return colors.background.default;
    }
  };

  return (
    <View
      {...props}
      style={[styles.container, {backgroundColor: getColor()}, style]}>
      <Text
        {...accessibility}
        allowFontScaling={false}
        style={styles.text}
        type={isBold ? TextType.Caption3Bold : TextType.Caption3}
        color={
          type === BadgeType.Default ? colors.text.light : colors.text.ondark
        }>
        {value}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 16,
    minWidth: 16,
    borderRadius: 8,
    paddingHorizontal: 5,
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
});

export default withTheme(Badge);

import React, {useRef, useCallback, useEffect} from 'react';
import {withTheme} from '../../core/theming';
import {Theme, Accessibility} from '../../types';
import Text, {TextType} from '../Typography/Text';
import Badge, {BadgeType} from '../Badge';
import {
  View,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';

type ValueBadge = {
  count?: number;
  type?: BadgeType;
};

type Value = {
  name: string;
  badge?: ValueBadge;
  accessibility?: Accessibility;
};

interface Props {
  onChange?: (index: number) => void;
  style?: StyleProp<ViewStyle>;
  values?: Value[];
  selectedIndex?: number;
  theme: Theme;
  fullWidth?: boolean;
}

const Tabs = ({
  style,
  values = [],
  selectedIndex = 0,
  theme: {colors, isRTL},
  fullWidth = false,
  onChange,
}: Props) => {
  const flatListRef = useRef<FlatList>(null);

  useEffect(() => {
    if (values?.length > selectedIndex) {
      flatListRef?.current?.scrollToIndex({
        animated: true,
        index: selectedIndex,
      });
    }
  }, [selectedIndex, values]);

  const renderItemBottomBoarder = useCallback(() => {
    return (
      <View
        style={[
          styles.itemBottomBoarder,
          {
            backgroundColor: colors.background.headerModal,
          },
        ]}
      />
    );
  }, [colors]);

  const renderItemBadge = useCallback((badge: ValueBadge) => {
    return (
      <Badge
        style={{marginHorizontal: 4}}
        type={badge.type}
        value={badge.count}
        isBold
      />
    );
  }, []);

  const renderItem = useCallback(
    ({item, index}: {item: Value; index: number}) => {
      const badge = item?.badge;

      return (
        <TouchableOpacity
          style={fullWidth && styles.fullWidthItem}
          key={index}
          accessibilityRole={onChange ? 'button' : undefined}
          {...item?.accessibility}
          onPress={() => onChange && onChange(index)}>
          <View
            style={[
              styles.itemButton,
              {
                flexDirection:
                  isRTL && Platform.OS === 'ios' ? 'row-reverse' : 'row',
              },
            ]}>
            <Text type={TextType.ButtonPrimary}>{item.name}</Text>
            {badge && (badge?.count || 0) > 0 && renderItemBadge(badge)}
          </View>
          {index === selectedIndex && renderItemBottomBoarder()}
        </TouchableOpacity>
      );
    },
    [
      renderItemBottomBoarder,
      renderItemBadge,
      onChange,
      selectedIndex,
      fullWidth,
      isRTL,
    ],
  );

  const renderFullWidth = () => {
    return values.map((x, i) => renderItem({item: x, index: i}));
  };

  return (
    <View
      style={[
        {
          backgroundColor: colors.background.white,
          borderBottomColor: colors.line.light,
        },
        fullWidth ? styles.fullWidthContainer : styles.container,
        style,
      ]}>
      {fullWidth ? (
        renderFullWidth()
      ) : (
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          ref={flatListRef}
          data={values || []}
          keyExtractor={(item) => item.name}
          renderItem={renderItem}
          onScrollToIndexFailed={() => console.log('scrollToIndex Error')}
          contentContainerStyle={{flexGrow: 1}}
          // This is needed to fix bug with wrong index on iOS when RTL
          // direction is only for iOS, so no need to check Android
          style={isRTL && {direction: 'ltr'}}
          inverted={isRTL && Platform.OS === 'ios'}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 48,
    borderBottomWidth: 1,
  },
  fullWidthItem: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidthContainer: {
    minHeight: 48,
    borderBottomWidth: 1,
    flex: 1,
    flexDirection: 'row',
  },
  itemButton: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  itemBottomBoarder: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 2,
  },
});

export default withTheme(Tabs);

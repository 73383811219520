import React from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';
import { ArrowDown } from '@suranceadmin/icons/native';
import TextInput from '../TextInput';
import { Theme, Accessibility } from '../../types';
import { withTheme } from '../../core/theming';

type Props = {
  onPress?: () => void;
  style?: ViewStyle;
  value?: string;
  theme: Theme;
  placeholder?: string;
  textInputAccessibility?: Accessibility;
  showsIcon?: boolean;
  isSmallScreen?: boolean;
};

const Dropdown = ({
  value,
  placeholder,
  textInputAccessibility,
  theme: { colors },
  showsIcon = true,
  isSmallScreen = false,
  ...props
}: Props) => {
  return (
    <TouchableOpacity {...props} accessible={false}>
      <View pointerEvents="none">
        <TextInput
          {...textInputAccessibility}
          value={value}
          isSmallScreen={isSmallScreen}
          placeholder={placeholder}
          icon={
            showsIcon && (
              <ArrowDown color={colors.icon.link} width={16} height={16} />
            )
          }
        />
      </View>
    </TouchableOpacity>
  );
};

export default withTheme(Dropdown);

import React, {useRef, useEffect, useState} from 'react';
import {
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
  Animated,
  I18nManager,
} from 'react-native';
import {withTheme} from '../../core/theming';
import {Theme} from '../../types';

type Props = {
  value: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
  theme: Theme;
};

const sanitizeProgressValue = (value: number) => {
  if (isNaN(value) || typeof value !== 'number' || value < 0) return 0;
  if (value > 100) return 100;
  return Math.round(value);
};

const LinearProgress = (props: Props) => {
  const {theme, style: styleProp, color, value} = props;
  const animation = useRef(new Animated.Value(0));
  const [elementWidth, setElementWidth] = useState(0);
  const sanitizedValue = sanitizeProgressValue(value);

  useEffect(() => {
    Animated.spring(animation.current, {
      toValue: sanitizedValue,
      useNativeDriver: true,
    }).start();
  }, [sanitizedValue]);

  const progressFill = animation.current.interpolate({
    inputRange: [0, 100],
    outputRange: [I18nManager.isRTL ? elementWidth : -elementWidth, 0],
    extrapolate: 'clamp',
  });

  return (
    <View
      style={[
        styles.progressBackground,
        {backgroundColor: theme.colors.background.default},
        styleProp,
      ]}
      onLayout={(event) => {
        const {width} = event.nativeEvent.layout;
        setElementWidth(width);
      }}>
      <Animated.View
        style={[
          styles.progressFill,
          {
            backgroundColor: color || theme.colors.icon.valid,
            transform: [{translateX: progressFill}],
            ...(elementWidth === 0 ? {display: 'none'} : {}),
          },
        ]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  progressBackground: {
    height: 4,
    overflow: 'hidden',
  },
  progressFill: {
    height: '100%',
  },
});

export default withTheme(LinearProgress);

import * as React from 'react';
import {FunctionComponent} from 'react';
import {I18nManager} from 'react-native';
import DefaultTheme from '../styles/DefaultTheme';
import {Theme} from '../types';
import {ThemeProvider} from './theming';

type Props = {
  theme?: Theme;
};

const Provider: FunctionComponent<Props> = ({
  children,
  theme,
}) => {
  const themeToUse = theme || DefaultTheme;
  const rtlTheme = {...themeToUse, isRTL: themeToUse.isRTL || I18nManager.isRTL};
  return (
    <ThemeProvider theme={rtlTheme}>{children}</ThemeProvider>
  );
};

export default Provider;

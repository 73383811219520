import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import Animated, {
  useSharedValue,
  withSpring,
  useAnimatedStyle,
  Extrapolation,
  interpolate,
} from 'react-native-reanimated';
import {Accessibility} from 'src/types';

type Props = {
  initIsSelected?: boolean;
  First: React.ElementType<any>;
  Second: React.ElementType<any>;
  onPress?: () => void;
  accessibility?: Accessibility;
};

const toggleAccessibilityState = (pressed: boolean) => {
  const accessbilityState = {
    accessibilityState: {
      checked: pressed,
    },
  };
  return accessbilityState;
};

const ToggleIcon = forwardRef(
  (
    {initIsSelected = true, First, Second, onPress, accessibility}: Props,
    ref,
  ) => {
    const [isPressed, setIsPressed] = useState(initIsSelected);
    const pressed = useSharedValue(initIsSelected ? 1 : 0);
    const secondStyle = useAnimatedStyle(() => {
      return {
        transform: [
          {
            scale: interpolate(
              pressed.value,
              [0, 1],
              [1, 0],
              Extrapolation.CLAMP,
            ),
          },
        ],
      };
    });

    const firstStyle = useAnimatedStyle(() => {
      return {
        transform: [
          {
            scale: pressed.value,
          },
        ],
        opacity: pressed.value,
      };
    });

    useImperativeHandle(ref, () => ({
      updateIcon: (isSelected: boolean) => {
        pressed.value = withSpring(isSelected ? 1 : 0);
        setIsPressed(isSelected);
      },
    }));

    const onIconPress = useCallback(() => {
      if (pressed.value === 0 || pressed.value === 1) {
        const newValue = pressed.value ? 0 : 1;
        setIsPressed(newValue === 1);
        pressed.value = withSpring(newValue);
        onPress && onPress();
      }
    }, [onPress, pressed]);

    return (
      <Pressable
        {...accessibility}
        accessibilityRole="button"
        {...toggleAccessibilityState(isPressed)}
        onPress={onIconPress}
        style={styles.container}>
        <Animated.View style={[StyleSheet.absoluteFillObject, secondStyle]}>
          <Second />
        </Animated.View>

        <Animated.View style={firstStyle}>
          <First />
        </Animated.View>
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
  },
});

export default ToggleIcon;

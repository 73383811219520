import {
  ValidationApprove,
  ValidationDecline,
  EyeOpen,
  EyeClosed,
} from '@suranceadmin/icons/native';
import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Animated,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput as RNTextInput,
  TextInputFocusEventData,
  TextStyle,
  Pressable,
  View,
} from 'react-native';

import { withTheme } from '../../core/theming';
import ToggleIcon from '../Icon/ToggleIcon';
import { Accessibility, Theme } from '../../types';
import Text, { TextType } from '../Typography/Text';

const smallDeviceFontSize = 12;

type Props = React.ComponentPropsWithRef<typeof RNTextInput> & {
  onIconPress?: () => void;
  style?: StyleProp<TextStyle>;
  value?: string;
  placeholder?: string;
  bottomLabel?: string;
  icon?: ReactNode;
  isValid?: boolean;
  secureTextEntry?: boolean;
  theme: Theme;
  bottomLabelAccessibility?: Accessibility;
  secureToggleAccessibility?: Accessibility;
  isSmallScreen?: boolean;
};

const animate = (
  active: boolean,
  fontSizeAnim: Animated.Value,
  translateYAnim: Animated.Value,
  isSmallScreen: boolean,
) => {
  const nextStyle = active ? activeStyle : inActiveStyle;
  if (isSmallScreen) {
    nextStyle.fontSize = active ? smallDeviceFontSize : activeStyle.fontSize;
  }

  Animated.parallel([
    Animated.timing(fontSizeAnim, {
      toValue: nextStyle.fontSize,
      duration: 200,
      useNativeDriver: false,
    }),
    Animated.timing(translateYAnim, {
      toValue: nextStyle.top,
      duration: 200,
      useNativeDriver: false,
    }),
  ]).start();
};

const TextInput = forwardRef((props: Props, ref) => {
  const {
    style,
    value,
    placeholder,
    bottomLabel,
    icon,
    isValid,
    isSmallScreen = false,
    secureTextEntry = false,
    theme: { fonts, colors, isRTL },
    onChangeText: onChangeTextProps,
    onBlur: onBlurProps,
    onFocus: onFocusProps,
    onIconPress,
    bottomLabelAccessibility,
    secureToggleAccessibility,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const [isSecureTextRevealed, setIsSecureTextRevealed] = useState(
    !secureTextEntry,
  );
  const RNTextInputRef = useRef<RNTextInput>(null);
  const translateYAnim = useRef(new Animated.Value(activeStyle.top)).current;
  const fontSizeAnim = useRef(new Animated.Value(isSmallScreen ? smallDeviceFontSize : activeStyle.fontSize)).current;
  const secureIconRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    revealSecureText: (isRevealed: boolean) => {
      if (secureTextEntry) {
        if (secureIconRef?.current) {
          secureIconRef.current.updateIcon(isRevealed);
        }

        setIsSecureTextRevealed(isRevealed);
      }
    },
  }));

  useEffect(() => {
    const isFocused = RNTextInputRef?.current?.isFocused();

    if (!isFocused) {
      animate(!!value, fontSizeAnim, translateYAnim, isSmallScreen);
    }
  }, [value, translateYAnim, fontSizeAnim, isSmallScreen]);

  useEffect(() => {
    setIsSecureTextRevealed(!secureTextEntry);
  }, [secureTextEntry]);

  useEffect(() => {
    if (isValid === false && secureTextEntry === true) {
      setIsSecureTextRevealed(false);
    }
  }, [isValid, secureTextEntry]);

  const onFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    animate(true, fontSizeAnim, translateYAnim, isSmallScreen);
    setIsActive(true);
    if (onFocusProps) {
      onFocusProps(e);
    }
  };

  const onBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsActive(false);
    if (!value) {
      animate(false, fontSizeAnim, translateYAnim, isSmallScreen);
    }
    if (onBlurProps) {
      onBlurProps(e);
    }
  };

  const onChangeText = (text: string) => {
    if (onChangeTextProps) {
      onChangeTextProps(text);
    }
  };

  const renderPlaceholder = () => {
    return (
      <Animated.Text
        accessible={false}
        style={[
          value || isActive ? fonts.caption2Bold : fonts.body,
          {
            fontSize: fontSizeAnim,
            color:
              value || isActive ? colors.text.disabled : colors.text.default,
            transform: [{ translateY: translateYAnim }],
          },
          { writingDirection: isRTL ? 'rtl' : 'ltr' },
        ]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: is not assignable to type AnimatedProps
        pointerEvents="none">
        {placeholder}
      </Animated.Text>
    );
  };

  const renderIcon = () => {
    if ((isValid === null || isValid === undefined) && !icon) {
      return null;
    } else {
      return <View style={styles.iconView}>{getIcon()}</View>;
    }
  };

  const getIcon = (): ReactNode => {
    if (isValid && secureTextEntry) {
      return (
        <ToggleIcon
          accessibility={secureToggleAccessibility}
          initIsSelected={isSecureTextRevealed}
          ref={secureIconRef}
          First={EyeOpen}
          Second={EyeClosed}
          onPress={() => {
            setIsSecureTextRevealed((prev) => !prev);
            onIconPress && onIconPress();
          }}
        />
      );
    }
    let iconToDraw = icon;
    if (isValid) {
      iconToDraw = <ValidationApprove color={colors.icon.valid} />;
    } else if (isValid === false) {
      iconToDraw = <ValidationDecline color={colors.icon.warning} />;
    }
    if (onIconPress) {
      return <Pressable onPress={onIconPress}>{iconToDraw}</Pressable>;
    } else {
      return iconToDraw;
    }
  };

  const renderBottomLabel = () => {
    return (
      <View style={styles.bottomLabelView}>
        <Text
          {...bottomLabelAccessibility}
          type={TextType.Caption2}
          color={isValid === false ? colors.text.warning : colors.text.light}>
          {bottomLabel}
        </Text>
      </View>
    );
  };

  const inputStyles: StyleProp<TextStyle> = [
    styles.input,
    fonts.body,
    {
      color: isValid === false ? colors.text.warning : colors.text.default,
    },
    { textAlign: isRTL ? 'right' : 'left' },
  ];

  return (
    <View style={style}>
      <View
        style={[
          styles.inputView,
          { borderBottomColor: colors.line.default },
          props.multiline ? { minHeight: 55 } : { height: 55 },
        ]}>
        <View style={{ flex: 1 }}>
          {renderPlaceholder()}

          <RNTextInput
            {...props}
            accessibilityLabel={props.accessibilityLabel || placeholder}
            ref={RNTextInputRef}
            style={inputStyles}
            underlineColorAndroid="transparent"
            secureTextEntry={!isSecureTextRevealed}
            placeholder=""
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            onChangeText={onChangeText}
          />
        </View>

        {renderIcon()}
      </View>

      {bottomLabel && renderBottomLabel()}
    </View>
  );
});

const styles = StyleSheet.create({
  inputView: {
    flexDirection: 'row',
    borderBottomWidth: 1,
  },
  input: {
    flex: 1,
    paddingLeft: 0,
    paddingVertical: 0,
  },
  iconView: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    paddingEnd: 12,
    paddingBottom: 7,
    alignSelf: 'flex-end',
  },
  bottomLabelView: {
    paddingTop: 8,
    display: 'flex',
  },
});

const inActiveStyle = {
  fontSize: 17,
  top: 23,
};

const activeStyle = {
  fontSize: 14,
  top: 0,
};

export default withTheme(TextInput);

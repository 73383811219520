import React from 'react';
import {View, StyleSheet, ViewStyle, StyleProp} from 'react-native';
import {withTheme} from '../../core/theming';
import {Theme} from '../../types';

type Props = {
  style?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  isVertical?: boolean;
  theme: Theme;
  children: React.ReactNode;
};

const ButtonGroup = ({isVertical = true, style, children, theme, itemStyle: itemCustomStyle}: Props) => {
  const containerStyle: StyleProp<ViewStyle>[] = [
    styles.container,
    {
      flexDirection: isVertical ? 'column' : 'row',
      backgroundColor: theme.colors.background.white,
    },
    style,
  ];
  const itemStyle: ViewStyle = isVertical
    ? styles.itemVertical
    : styles.itemHorizontal;

  return (
    <View style={containerStyle}>
      {React.Children.toArray(children)
        .filter((x) => React.isValidElement(x))
        .map((item, index) => {
          return (
            <View
              key={index}
              style={index === 0 ? !isVertical && {flex: 1} : [itemStyle, itemCustomStyle]}>
              {item}
            </View>
          );
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 36,
    paddingVertical: 24,
  },
  itemVertical: {
    marginTop: 24,
  },
  itemHorizontal: {
    flex: 1,
    marginLeft: 16,
  },
});

export default withTheme(ButtonGroup);

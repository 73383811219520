import {Close, Photo} from '@suranceadmin/icons/native';
import React, {VoidFunctionComponent} from 'react';
import {Image, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {Accessibility, Theme} from '../../types';
import LinkButton from '../Button/LinkButton';
import {withTheme} from '../../core/theming';

type Props = {
  theme: Theme;
  deviceImage?: string;
  deviceIcon?: React.ReactElement;
  onAddImage?: () => void;
  onRemoveImage?: () => void;
  addPhotoTitle?: string;
  imageAccessibility?: Accessibility;
  buttonAccessibility?: Accessibility;
  style?: StyleProp<ViewStyle>;
};

const ImagePicker: VoidFunctionComponent<Props> = ({
  theme: {colors},
  deviceImage,
  deviceIcon,
  onAddImage,
  onRemoveImage,
  addPhotoTitle,
  imageAccessibility,
  buttonAccessibility,
  style,
}) => {
  return (
    <View style={style}>
      {deviceImage ? (
        <View style={styles.deviceImageView}>
          <Image
            {...imageAccessibility}
            style={styles.deviceImage}
            source={{uri: deviceImage}}
          />

          <LinkButton
            {...buttonAccessibility}
            style={styles.deviceImageButton}
            onPress={onRemoveImage}
            icon={<Close width={16} height={16} color={colors.icon.link} />}
          />
        </View>
      ) : (
        <View style={styles.deviceIconView}>
          {deviceIcon &&
            React.cloneElement(deviceIcon, {...imageAccessibility})}

          <LinkButton
            {...buttonAccessibility}
            style={styles.deviceIconButton}
            onPress={onAddImage}
            title={addPhotoTitle}
            icon={
              <Photo
                width={16}
                height={16}
                color={colors.icon.link}
                style={{marginRight: 8}}
              />
            }
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  deviceImageView: {
    width: 120,
    height: 120,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  deviceImage: {
    width: 104,
    height: 104,
    borderRadius: 52,
    resizeMode: 'cover',
    alignSelf: 'center',
  },
  deviceImageButton: {
    position: 'absolute',
    right: -20,
    top: -20,
  },
  deviceIconView: {
    height: 120,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceIconButton: {
    position: 'absolute',
    right: 0,
    top: -20,
  },
});

export default withTheme(ImagePicker);

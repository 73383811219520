import React from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  ImageBackground,
  StatusBar,
  StyleSheet,
  ImageSourcePropType,
} from 'react-native';
import {getStatusBarHeight, getPaddingFromStatusBar} from '../../constants';
import {withTheme} from '../../core/theming';
import {Theme, Accessibility} from '../../types';
import Text, {TextType} from '../Typography/Text';
import Badge, {BadgeType} from '../Badge';
import {headerBackground, iconNotification} from '../../assets';
import { increasedTapZone } from '../../common';

export enum HeaderType {
  Default = 'default',
  Transparent = 'transparent',
  Warning = 'warning',
}

type Props = {
  leftComponent?: {
    name?: string;
    icon?: React.ReactNode;
    accessibility?: Accessibility;
    action?: () => void;
  };
  rightComponent?: {
    icon?: React.ReactNode;
    accessibility?: Accessibility;
    action?: () => void;
  };
  notificationComponent?: {
    badgeCount?: number;
    accessibility?: Accessibility;
    action?: () => void;
  };
  type?: HeaderType;
  title?: string;
  titleAccessibility?: Accessibility;
  translucent?: boolean;
  barStyle?: 'default' | 'light-content' | 'dark-content';
  theme: Theme;
  transparentColor?: string;
  defaultBackgroundImage?: ImageSourcePropType;
  warningBackgroundImage?: ImageSourcePropType;
};

const Header = ({
  leftComponent,
  rightComponent,
  notificationComponent,
  type = HeaderType.Default,
  titleAccessibility,
  title,
  translucent,
  barStyle = 'light-content',
  theme: {colors},
  transparentColor,
  defaultBackgroundImage,
  warningBackgroundImage,
}: Props) => {
  const topButtonsView = () => {
    return (
      <View style={styles.topButtonsView}>
        {leftComponent && (
          <TouchableOpacity
            {...increasedTapZone()}
            accessibilityRole={'button'}
            {...leftComponent.accessibility}
            style={styles.leftComponentButton}
            onPress={leftComponent?.action}>
            {leftComponent?.icon}
            <Text type={TextType.OverlineBold} color={colors.text.ondark}>{leftComponent?.name}</Text>
          </TouchableOpacity>
        )}

        <View style={styles.rightComponentButton}>{rightComponents()}</View>
      </View>
    );
  };

  const rightComponents = () => {
    return (
      <View style={styles.rightComponentsView}>
        {notificationComponent && (
          <TouchableOpacity
            accessibilityRole={'button'}
            {...increasedTapZone()}
            {...notificationComponent.accessibility}
            onPress={notificationComponent?.action}>
            <Image source={iconNotification} style={styles.image} />

            {!!notificationComponent?.badgeCount && (
              <View
                style={styles.badgeView}>
               <Badge type={BadgeType.Critical} value={notificationComponent?.badgeCount} />
              </View>
            )}
          </TouchableOpacity>
        )}

        {rightComponent?.icon && (
          <TouchableOpacity
            accessibilityRole={'button'}
            {...increasedTapZone()}
            style={notificationComponent ? styles.rightComponent : styles.rightComponentCompact}
            {...rightComponent.accessibility}
            onPress={rightComponent?.action}>
            {rightComponent?.icon}
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const getBackgroundColor = () => {
    switch (type) {
      case HeaderType.Transparent:
        return transparentColor || 'transparent';
      case HeaderType.Warning:
        return colors.background.warning;
      case HeaderType.Default:
      default:
        return colors.background.header;
    }
  };

  const style = [styles.container, {backgroundColor: getBackgroundColor()}];
  const customBackground = type === HeaderType.Default ? defaultBackgroundImage : warningBackgroundImage;
  const backgroundImage = customBackground || headerBackground;
  return (
    <ImageBackground
      style={style}
      source={type === HeaderType.Transparent ? null : backgroundImage}>
      <StatusBar barStyle={barStyle} translucent={translucent} />

      {leftComponent && topButtonsView()}

      <View style={styles.titleView}>
        {/* // VS: Container is needed not to override paddings that fix font centering */}
        {!!title && title.length > 0 && <View style={[styles.titleTextContainer, leftComponent && styles.titleTextWithLeftComponent]}>
          <Text
            {...titleAccessibility}
            type={TextType.Headline2}
            color={colors.text.ondark}>
            {title}
          </Text>
        </View> }
        {!leftComponent && rightComponents()}
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: getStatusBarHeight() + getPaddingFromStatusBar(),
  },
  topButtonsView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 24,
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
    minHeight: 20,
    paddingBottom: 16,
  },
  titleTextContainer: {
    flex: 1,
    paddingTop: 0,
  },
  titleTextWithLeftComponent: {
    paddingTop: 20,
  },
  leftComponentButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    marginLeft: 24,
  },
  rightComponentButton: {
    paddingRight: 24,
  },
  rightComponentsView: {
    flexDirection: 'row',
    height: '100%',
  },
  rightComponent: {
    marginLeft: 24,
  },
  rightComponentCompact: {
    paddingLeft: 8,
  },
  image: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  badgeView: {
    position: 'absolute',
    right: -5,
    top: -5,
  },
});

export default withTheme(Header);

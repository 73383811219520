import React, {FunctionComponent} from 'react';
import {View, ViewStyle} from 'react-native';

// Taken from expo-blur repo
const getBackgroundColor = (
  blurAmount: number,
  blurType: 'dark' | 'light' | 'default',
): string => {
  const opacity = blurAmount / 100;
  switch (blurType) {
    case 'dark':
      // From apple.com
      return `rgba(28,28,28,${opacity * 0.65})`;
    case 'light':
      // From https://www.apple.com/newsroom
      return `rgba(255,255,255,${opacity * 0.7})`;
    case 'default':
    default:
      // From xcode composition
      return `rgba(255,255,255,${opacity * 0.3})`;
  }
};

const BlurView: FunctionComponent<BlurProps> = (props) => {
  const {blurType = 'dark', blurAmount = 40, style, children} = props;
  const blurStyle = {
    backgroundColor: getBackgroundColor(blurAmount, blurType),
    // Is not supported in Firefox
    backdropFilter: `saturate(180%) blur(${blurAmount * 0.2}px)`,
  };
  return <View style={[blurStyle, style]}>{children}</View>;
};

type BlurProps = {
  blurType: 'dark' | 'light' | 'default';
  blurAmount: number;
  style?: ViewStyle;
  children?: React.ReactNode;
};

export default BlurView;

import React, {useState, useCallback} from 'react';
import {withTheme} from '../../core/theming';
import {Theme} from '../../types';
import {
  ScrollView as RNScrollView,
  StyleProp,
  ViewStyle,
  NativeScrollEvent,
  NativeSyntheticEvent,
  LayoutChangeEvent,
  View,
  StyleSheet,
} from 'react-native';

type Props = React.ComponentPropsWithRef<typeof RNScrollView> & {
  onScroll?: () => NativeSyntheticEvent<NativeScrollEvent>;
  style?: StyleProp<ViewStyle>;
  theme: Theme;
  children: React.ReactNode;
  isBorderEnabled?: boolean;
  scrollRef?: React.RefObject<RNScrollView>;
  lineColor?: string;
};

const ScrollView = (props: Props) => {
  const {
    onScroll: onScrollProps,
    style: styleProps,
    children,
    theme: {colors},
    isBorderEnabled = true,
    scrollRef,
    lineColor,
  } = props;
  const [isShowingBorder, setShowingBorder] = useState(false);
  const [scrollViewContentHeight, setScrollViewContentHeight] = useState(0);

  const onScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      const y = -e?.nativeEvent?.contentOffset?.y || 0;
      const contentHeight = e?.nativeEvent?.contentSize?.height || 0;
      const scrollViewHeight = e?.nativeEvent?.layoutMeasurement?.height || 0;
      const isShowing = contentHeight + y >= scrollViewHeight;

      setShowingBorder(isShowing);

      if (onScrollProps) {
        onScrollProps(e);
      }
    },
    [onScrollProps],
  );

  const onLayout = (e: LayoutChangeEvent) => {
    const height = e?.nativeEvent?.layout?.height || 0;
    setShowingBorder(height < scrollViewContentHeight);
  };

  const onContentSizeChange = (_: number, h: number) => {
    setScrollViewContentHeight(h)
  };

  return (
    <>
    <RNScrollView
      {...props}
      ref={scrollRef}
      style={styleProps}
      scrollEventThrottle={16}
      onScroll={onScroll}
      onContentSizeChange={onContentSizeChange}
      onLayout={onLayout}>
      {children}
    </RNScrollView>
    {isBorderEnabled && <View style={[styles.bottomBorder, {
        backgroundColor: lineColor || colors.line.default,
        opacity: isShowingBorder ? 0.34 : 0,
    }]} />}
    </>
  );
};

const styles = StyleSheet.create({
  bottomBorder: {
    width: '100%',
    height: 1,
  }
});

export default withTheme(ScrollView);

import * as React from 'react';
import {Platform, StyleProp, Text as RNText, TextStyle} from 'react-native';
import {withTheme} from '../../core/theming';
import {Font, Theme} from '../../types';

export enum TextType {
  Headline1 = 'headline1',
  Headline2 = 'headline2',
  Subtitle = 'subtitle',
  SubtitleBold = 'subtitleBold',
  Body = 'body',
  BodyBold = 'bodyBold',
  BodyWide = 'bodyWide',
  ButtonPrimary = 'buttonPrimary',
  ButtonSecondary = 'buttonSecondary',
  Overline = 'overline',
  OverlineBold = 'overlineBold',
  Caption1 = 'caption1',
  Caption1Bold = 'caption1Bold',
  Caption2 = 'caption2',
  Caption2Bold = 'caption2Bold',
  Caption3 = 'caption3',
  Caption3Bold = 'caption3Bold',
}

type Props = React.ComponentProps<typeof RNText> & {
  style?: StyleProp<TextStyle>;
  type?: TextType;
  color?: string;
  locale?: string;
  theme: Theme;
  children: React.ReactNode;
};

const Text = ({
  style,
  type = TextType.Body,
  theme: {colors, fonts, isRTL},
  color = colors.text.default,
  children,
  ...props
}: Props) => {
  const typeCustomStyle = getFontByType();
  const styles = [typeCustomStyle, {color: color}, style];
  const offsetTop =
    typeCustomStyle.lineHeight &&
    typeCustomStyle.fontSize &&
    typeCustomStyle.lineHeight < typeCustomStyle.fontSize
      ? typeCustomStyle.fontSize - typeCustomStyle.lineHeight
      : null;
  return (
    <RNText
      {...props}
      style={[
        offsetTop !== null &&
        Platform.select({
          ios: {
            paddingTop: offsetTop,
          },
          android: {
            paddingTop: offsetTop,
          },
        }),
        {writingDirection: isRTL ? 'rtl' : 'ltr'},
        styles,
      ]}>
      {children}
    </RNText>
  );

  function getFontByType(): Font {
    return fonts[type] || fonts.body;
  }
};

export default withTheme(Text);

// Code from - https://github.com/ovr/react-native-status-bar-height/blob/master/index.js

import {Dimensions, Platform} from 'react-native';

const STATUSBAR_DEFAULT_HEIGHT_IOS = 28;
const STATUSBAR_DEFAULT_HEIGHT_ANDROID = 20;
const STATUSBAR_X_HEIGHT = 44;
const STATUSBAR_IP12_HEIGHT = 47;
const STATUSBAR_IP12MAX_HEIGHT = 47;
const STATUSBAR_IP13MINI_HEIGHT = 50;
const STATUSBAR_IP14PRO_HEIGHT = 59;

const X_WIDTH = 375;
const X_HEIGHT = 812;

const XSMAX_WIDTH = 414;
const XSMAX_HEIGHT = 896;

const IP12_WIDTH = 390;
const IP12_HEIGHT = 844;

const IP12MAX_WIDTH = 428;
const IP12MAX_HEIGHT = 926;

const IP13MINI_WIDTH = 375;
const IP13MINI_HEIGHT = 812;

const IP14PRO_WIDTH = 393;
const IP14PRO_HEIGHT = 852;

const IP14MAX_WIDTH = 430;
const IP14MAX_HEIGHT = 932;

const {height: W_HEIGHT, width: W_WIDTH} = Dimensions.get('window');

let statusBarHeight =
  Platform.OS === 'ios'
    ? STATUSBAR_DEFAULT_HEIGHT_IOS
    : STATUSBAR_DEFAULT_HEIGHT_ANDROID;

if (Platform.OS === 'ios' && !Platform.isPad && !Platform.isTVOS) {
  if (W_WIDTH === X_WIDTH && W_HEIGHT === X_HEIGHT) {
    statusBarHeight = STATUSBAR_X_HEIGHT;
  } else if (W_WIDTH === XSMAX_WIDTH && W_HEIGHT === XSMAX_HEIGHT) {
    statusBarHeight = STATUSBAR_X_HEIGHT;
  } else if (W_WIDTH === IP12_WIDTH && W_HEIGHT === IP12_HEIGHT) {
    statusBarHeight = STATUSBAR_IP12_HEIGHT;
  } else if (W_WIDTH === IP12MAX_WIDTH && W_HEIGHT === IP12MAX_HEIGHT) {
    statusBarHeight = STATUSBAR_IP12MAX_HEIGHT;
  } else if (W_WIDTH === IP13MINI_WIDTH && W_HEIGHT === IP13MINI_HEIGHT) {
    statusBarHeight = STATUSBAR_IP13MINI_HEIGHT;
  } else if (W_WIDTH === IP14PRO_WIDTH && W_HEIGHT === IP14PRO_HEIGHT) {
    statusBarHeight = STATUSBAR_IP14PRO_HEIGHT;
  } else if (W_WIDTH >= IP14MAX_WIDTH && W_HEIGHT >= IP14MAX_HEIGHT) {
    statusBarHeight = STATUSBAR_IP14PRO_HEIGHT;
  }
}

export const isIPhoneX = W_WIDTH >= X_WIDTH && W_HEIGHT >= X_HEIGHT;

export function getStatusBarHeight(): number {
  return Platform.select({
    ios: statusBarHeight,
    // VS: Use StatusBar.currentHeight for Android when can enable translucent in both Header and ModalHeader.
    // Also requires setting statusBarTranslucent={true} on all modal windows, otherwise the modal will not be fullscreen.
    android: statusBarHeight,
    default: 0,
  });
}

export function getPaddingFromStatusBar(): number {
  return Platform.select({
    ios: 8,
    android: 8,
    default: 20,
  });
}

import {Check} from '@suranceadmin/icons/native';
import React, {VoidFunctionComponent} from 'react';
import {StyleSheet, ViewStyle} from 'react-native';
import colors from '../../styles/colors';
import Card from '../Card';
import Text, {TextType} from '../Typography/Text';

type ListItemProps = {
  onPress?: () => void;
  style?: ViewStyle;
  title: string;
  isSelected: boolean;
  isShadowDisplayed?: boolean;
  icon?: React.ReactNode;
  iconColor?: string;
};

const ListItem: VoidFunctionComponent<ListItemProps> = ({
  onPress,
  style,
  title,
  isSelected,
  isShadowDisplayed = false,
  iconColor = colors.icon.link,
  icon = <Check color={iconColor}/>,
}) => {
  return (
    <Card
      onPress={onPress}
      isShadowDisplayed={isShadowDisplayed}
      style={[styles.view, style]}>
      <Text type={TextType.Body}>{title}</Text>
      {isSelected && icon}
    </Card>
  );
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 14,
    paddingHorizontal: 40,
  },
});

export default ListItem;

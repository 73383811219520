import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  ImageSourcePropType,
  ImageStyle,
  ViewStyle,
} from 'react-native';
import {withTheme} from '../../core/theming';
import Text, {TextType} from '../Typography/Text';
import Card from '../Card';
import {Theme} from '../../types';
import {iconArrowRight} from '../../assets';

const IconListItem = (props: IconListItemProps) => {
  const renderIcon = (icon: ImageSourcePropType, style?: ImageStyle) => {
    if (!icon) return null;

    return <Image source={icon} style={style} />;
  };

  const chevron = props.chevron || iconArrowRight;
  const {isShadowDisplayed = true} = props;
  return (
    <Card
      isShadowDisplayed={isShadowDisplayed}
      onPress={props.onPress}
      style={[styles.view, props.style]}>
      <View style={[styles.iconTitleView]}>
        {props.icon && renderIcon(props.icon, props.iconStyle)}
        <View style={[styles.labelView]}>
          <Text type={TextType.Subtitle}>{props.title}</Text>
          {!!props.subTitle && (
            <Text style={styles.subTitleView} type={TextType.Caption2}>
              {props.subTitle}
            </Text>
          )}
        </View>
      </View>
      {renderIcon(chevron, styles.chevronStyle)}
    </Card>
  );
};

type IconListItemProps = {
  onPress?: () => void;
  style?: ViewStyle;
  title: string;
  subTitle?: string;
  icon: ImageSourcePropType;
  iconStyle: ImageStyle;
  chevron?: ImageSourcePropType;
  chevronStyle?: ImageStyle;
  isShadowDisplayed?: boolean;
  theme: Theme;
};

const styles = StyleSheet.create({
  view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingRight: 12,
    paddingLeft: 25,
  },
  iconTitleView: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  labelView: {
    flex: 1,
    marginLeft: 8,
  },
  subTitleView: {
    marginTop: 4,
  },
  chevronStyle: {alignSelf: 'center', height: 16, width: 11, marginLeft: 10},
});

export default withTheme(IconListItem);

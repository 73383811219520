import React from 'react';
import { withTheme } from '../../core/theming';
import { Theme } from '../../types';
import Text, { TextType } from '../Typography/Text';
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native';

type Props = {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  color?: string;
  title?: string;
  textType?: TextType;
  icon?: React.ReactElement;
  theme: Theme;
};

const LinkButton = ({
  style,
  title,
  icon,
  theme,
  color = theme.colors.button.primary,
  textType = TextType.Caption1,
  ...props
}: Props) => {
  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.container,
        style,
        { flexDirection: theme.isRTL ? 'row-reverse' : 'row' },
      ]}
      accessibilityRole={'button'}
      activeOpacity={0.5}>
      {icon &&
        React.cloneElement(
          icon,
          theme.isRTL ? { marginStart: 8 } : { marginEnd: 8 },
        )}

      <Text color={color} allowFontScaling={false} type={textType} numberOfLines={1}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
  },
});

export default withTheme(LinkButton);

import React from 'react';
import {View, Modal, ViewStyle, StyleSheet} from 'react-native';
import Card from '../Card';
import BlurView from './BlurView';
import {Theme, Accessibility} from '../../types';
import {withTheme} from '../../core/theming';

const ModalAlert = (props: ModalAlertProps) => {
  const {visible, onClose, children, style, blurViewProps = {}, accessibility} = props;
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}>
      <View style={styles.container}>
        <BlurView blurType="dark" style={styles.blurView} {...blurViewProps} />
        <Card accessibility={accessibility} style={[styles.mainView, style]}>
          {children}
        </Card>
      </View>
    </Modal>
  );
};

type ModalAlertProps = {
  style?: ViewStyle;
  theme: Theme;
  children: React.ReactNode;
  onClose?: () => void;
  visible?: boolean;
  accessibility?: Accessibility;
  blurViewProps?: typeof BlurView;
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  blurView: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  mainView: {
    alignItems: 'center',
  },
});

export default withTheme(ModalAlert);
